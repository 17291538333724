import * as React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

// Pages
import Index from "./index";
import Journal from "./journal";
import Projects from "./projects";

// Templates
import About from "../templates/about";
import Article from "../templates/article";
import Project from "../templates/project";
import Page from "../templates/page";

const NotFoundPage = () => (
  <>
    <h1>Page not found!</h1>
  </>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    about: About,
    homepage: Index,
    journal: Journal,
    projects: Projects,
    article: Article,
    project: Project,
    page: Page,
  },
});
